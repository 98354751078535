<template>
    <OptionsModal
        v-if="dataMap.id && !forceReload"
        :id="`optionsModal${dataMap.id}`"
        :item="dataMap"
        :allow-multiple-drafts="false"
        :permissions="{
            update: canManage && !isPublished(dataMap, 'obsoleteAt'),
            update_status: canManage,
            delete: canManage,
        }"
        :saving="[]"
        is-static
        visible
        data-analytics-location="Mapping Options Modal"
        @hidden="handleHidden"
        @item:obsolete="handleObsolete"
        @item:delete="handleDeleteDataMap"
        @item:unschedule-obsolete="handleUnscheduleObsolete"
        @version:create-draft="handleCreateDraft"
        @version:publish="handlePublish"
        @version:delete="handleDeleteVersion"
        @version:unschedule-publish="handleUnschedulePublish"
        @version:revert="handleRevert"
        @version:unschedule-revert="handleUnscheduleRevert"
    >
        <template #additional-info>
            <div class="form-group">
                <label>Code</label>
                <span class="title text-break">{{ dataMap.code }}</span>
            </div>
        </template>

        <template #version-content="{ version }">
            <div class="version-details col-12 pt-2">
                <div class="col-12 p-0">
                    <div class="form-group mb-2">
                        <label class="mb-0">Title</label>
                        <span class="text-break">
                            {{ version.title }}
                        </span>
                    </div>
                </div>

                <div class="col-12 p-0">
                    <div class="form-group mb-1">
                        <label class="mb-0">Operations</label>
                        TODO
                    </div>

                    <RelatedItemView
                        :item-id="parseInt(dataMap.id)"
                        item-type="data map"
                        :item-version-id="parseInt(version.id)"
                        class="mt-2"
                    />
                </div>
            </div>
        </template>

        <template #options-left="{ version }">
            <RouterLink
                v-if="!isObsolete(version.dataMap) && !isPublished(version) && canManage"
                :to="{ name: 'admin.datamaps.builder', params: { dataMapId: dataMap.id, versionId: version.id }}"
                class="btn btn-tertiary mr-1"
                data-cy="editVersion"
            >
                <FontAwesomeIcon
                    icon="pencil-alt"
                    class="mr-1"
                />

                Edit Version
            </RouterLink>

            <RouterLink
                v-else
                :to="{ name: 'admin.datamaps.builder', params: { dataMapId: dataMap.id, versionId: version.id }}"
                class="btn btn-tertiary mr-1"
                data-cy="viewVersion"
            >
                <FontAwesomeIcon
                    icon="eye"
                    class="mr-1"
                />

                View DataMap
            </RouterLink>
        </template>
    </OptionsModal>
</template>

<script>
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import OptionsModal from '@imt/vue-versioning/src/components/OptionsModal.vue';
    import RelatedItemView from '@imt/vue-versioning/src/components/RelatedItemView.vue';
    import versionMixin from '@imt/vue-versioning/src/mixins/versioning';
    import {mapActions, mapMutations, mapState} from 'vuex';

    import authMixin from '../mixins/auth';

    export default {
        name: 'MDataMapOptionsModal',
        components: {
            OptionsModal,
            RelatedItemView
        },
        mixins: [
            authMixin,
            toastsMixin,
            versionMixin,
        ],
        metaInfo: {
            title: 'Edit Data Map',
        },
        data() {
            return {
                fetchedDataMap: {},
                forceReload: false,
            };
        },
        computed: {
            dataMap() {
                return this.dataMaps.find(p => p.id == this.$route.params.id) || this.fetchedDataMap; // eslint-disable-line eqeqeq
            },
            ...mapState('mapping', [
                'dataMaps',
            ]),
        },
        async created() {
            await this.initialize();
        },
        methods: {
            async initialize() {
                if (!this.dataMap.id) {
                    this.fetchedDataMap = await this.fetchDataMap(this.$route.params.id) || {};

                    if (!this.fetchedDataMap.id) {
                        await this.$router.push({name: 'admin.datamaps.list'});
                        this.error('This data map was not found or does not exist.', 'Data Map Not Found');
                    }
                }
            },
            async handleCreateDraft(versionId) {
                try {
                    await this.createDraft(versionId);
                    // DO NOT REMOVE - THIS IS NEEDED TO FIX A WEIRD VUE EDGE CASE BUG
                    this.forceReload = true;
                    await this.$nextTick();
                    this.forceReload = false;
                    // END OF EDGE CASE FIX
                    this.success('Successfully created draft!', 'Create Draft');
                } catch (error) {
                    this.error('Failed to create draft!', 'Create Draft');
                }
            },
            async handlePublish({version, date}) {
                try {
                    let updatedVersion = await this.publishVersion({
                            versionId: version.id,
                            date,
                        }),
                        action = this.isPublished(updatedVersion) ? 'published' : 'scheduled';

                    this.success(`Successfully ${action} version!`, 'Publish Version');
                } catch (error) {
                    this.error('Failed to publish version!', 'Publish Version');
                }
            },
            async handleUnschedulePublish({version}) {
                try {
                    await this.unschedulePublish(version.id);
                    this.success('Successfully unscheduled version!', 'Publish Version');
                } catch (error) {
                    this.error('Failed to unschedule version!', 'Publish Version');
                }
            },
            async handleRevert({version, date}) {
                try {
                    let updatedVersion = await this.revertVersion({
                            versionId: version.id,
                            date,
                        }),
                        action = this.isReverted(updatedVersion) ?
                            'reverted version' :
                            'scheduled version to be reverted';

                    this.success(`Successfully ${action}!`, 'Revert Version');
                } catch (error) {
                    this.error('Failed to revert version!', 'Revert Version');
                }
            },
            async handleUnscheduleRevert({version}) {
                try {
                    await this.revertVersion({versionId: version.id});
                    this.success('Successfully unscheduled revert!', 'Revert Version');
                } catch (error) {
                    this.error('Failed to unschedule revert!', 'Revert Version');
                }
            },
            async handleObsolete(date) {
                try {
                    let updatedDataMap = await this.obsoleteDataMap({
                            dataMapId: this.dataMap.id,
                            date: date.date,
                        }),
                        action = this.isScheduledForObsolete(updatedDataMap) ?
                            'scheduled data map to be obsoleted' :
                            'obsoleted data map';

                    this.success(`Successfully ${action}!`, 'Obsolete Data Map');
                } catch (error) {
                    this.error('Failed to obsolete data map!', 'Obsolete Data Map');
                }
            },
            async handleUnscheduleObsolete() {
                try {
                    await this.obsoleteDataMap({dataMapId: this.dataMap.id});
                    this.success('Successfully unscheduled obsolete!', 'Obsolete Data Map');
                } catch (error) {
                    this.error('Failed to unschedule obsolete!', 'Obsolete Data Map');
                }
            },
            async handleDeleteVersion({version}) {
                try {
                    await this.deleteVersion(version);
                    this.success('Successfully deleted version!', 'Delete Version');
                } catch (error) {
                    this.error('Failed to delete version!', 'Delete Version');
                }
            },
            async handleDeleteDataMap() {
                try {
                    await this.deleteDataMap(this.dataMap.id);
                    this.$bvModal.hide(`optionsModal${this.dataMap.id}`);
                    this.success('Successfully deleted data map!', 'Delete Data Map');
                } catch (error) {
                    this.error('Failed to delete data map!', 'Delete Data Map');
                }
            },
            async handleHidden() {
                await this.$router.push({name: 'admin.datamaps.list'});
            },
            async handleUpdateVersion(versionId, data) {
                try {
                    await this.updateVersion({
                        versionId,
                        attributes: {[data.field]: data.value},
                    });

                    this.success('Successfully updated version!', 'Version Update');
                } catch (error) {
                    this.error('Failed to update version!', 'Version Update');
                }
            },
            ...mapActions('mapping', [
                'createDraft',
                'deleteDataMap',
                'deleteVersion',
                'fetchDataMap',
                'obsoleteDataMap',
                'publishVersion',
                'revertVersion',
                'updateVersion',
                'unschedulePublish',
            ]),
            ...mapMutations('mapping', ['SET_DATA']),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    body
        &,
        &.dark.mode
            .form-group
                font-weight: normal
                margin-bottom: 1rem

                label
                    width: 100%
                    font-weight: bold
</style>
